import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import './css/output.css';
import 'typeface-inter';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App />
);